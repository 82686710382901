import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { AdsGETApiAuth, AdsGETApiWithToken } from '../../../Constant/api';
import { slugs } from '../../../Constant/slugs';
import store from '../../../redux/store';
import NotFound from '../../NotFound';
import FirstScreenExam from './FirstScreen';
import { useHistory } from 'react-router-dom';

export default function StartClassExam(props) {
  const dispatch = useDispatch()
  const location = useLocation()
  const [ExamData, setAllExamList] = React.useState({});
  const [ExamFetch, setExamFetch] = React.useState(false);
  const [NotFoundData, setNotFound] = React.useState(false)
  const [reload, setReload] = React.useState(false)
  const [choose, setChoose] = React.useState(0)
  const [AnswerArray, setAnswerArray] = React.useState([]);
  const [ExamSlug, setExamSlug] = React.useState("");
  const [ResultData, setResultData] = React.useState({ meta: { exam_date_results: "" } });
  const token = localStorage.getItem('teacher_token');
  const history = useHistory()

  const fetchExam = (slug) => {
    setExamFetch(false)
    setReload(true)
    setNotFound(true)
    const id = store.getState().LoginState.userID

    const exam_slug = localStorage.getItem('teacher_exam_slug');
    setExamSlug(exam_slug)
    AdsGETApiWithToken({ slug: slug }, exam_slug, token)
      .then((res) => {
        if (res.data.length == 1) {
          if (res.data[0].meta.exam_question_block_student != undefined) {
            setAllExamList(res.data[0])
            setExamFetch(true)
            console.log("asdsad")
            setNotFound(false)
          }
          else {
            console.log("asdsad")
            setExamFetch(true)
            setNotFound(true)
          }
        }
        else {
          setExamFetch(true)
          console.log("asdsad")
          setNotFound(true)
        }
        setReload(false)
      })
      .catch(err => {
        console.log(err)
        console.log(err.response.data)
        history.pushState(slugs.classroom)
      })

  }
  const [finishStatus, setfinishStatus] = React.useState(false);

  useEffect(() => {
    fetchExam(location.pathname.slice(slugs.start_class_exam.length + 1))
  }, [])

  return (
    <div style={{ backgroundColor: "#eee", minHeight: "90vh" }}>
      <div>
        <div className="row">
          <div className="col-sm-2 col-1"></div>
          <div className="col-sm-8 col-10 form-view m-3">
            {ExamFetch &&
              <>
                {NotFoundData ?
                  <NotFound /> :
                  <>
                    {/* {JSON.stringify(ExamData.meta.exam_question_block_text)} */}
                    <FirstScreenExam
                      ExamData={ExamData}
                      setReload={setReload}
                      choose={choose}
                      setChoose={setChoose}
                      AnswerArray={AnswerArray}
                      setAnswerArray={setAnswerArray}
                      ResultData={ResultData} setResultData={setResultData}
                      ExamSlug={ExamSlug}
                    />

                  </>
                }
              </>
            }
          </div>
        </div>
      </div>
      {reload &&
        <div className="modal" style={{ padding: "50vw" }}>
          <div className="dot-pulse"></div>
        </div>
      }
    </div>
  )
}