// import React, { useEffect } from "react";
// import { Link } from "react-router-dom";
// import DataTable from "react-data-table-component";
// import { AdsGETApiAuth } from "../../../Constant/api";
// import { slugs } from "../../../Constant/slugs";
// const columns = [
//   {
//     id: 1,
//     name: "id",
//     selector: (row) => row.meta.user_id,
//     sortable: true,
//     reorder: true,
//   },
//   {
//     id: 31,
//     name: "Title",
//     selector: (row) => row.title.rendered,
//     sortable: true,
//     reorder: true
//   },
//   {
//     id: 312,
//     name: "Phone",
//     selector: (row) => row.meta.user_phone_number,
//     sortable: true,
//     reorder: true
//   },
//   {
//     id: 313,
//     name: "Email",
//     selector: (row) => row.meta.user_email,
//     sortable: true,
//     reorder: true
//   },
//   {
//     id: 32,
//     name: "role",
//     selector: (row) => row.meta.user_role,
//     sortable: true,
//     reorder: true
//   },
//   {
//     id: 3,
//     name: "View",
//     right: true,
//     cell: (row) => <Link to={`${slugs.student_details}${row.slug}`}>View</Link>,

//   }
// ];

// export default function AllStudents() {
//   const [Projects1, setProjects1] = React.useState([])
//   const [CustomersList, setCustomersList] = React.useState([])
//   const [Reload, setReload] = React.useState(false)
//   const [SubscriberTotal, setSubscriberTotal] = React.useState(0)
//   const [StudentTotal, setStudentTotal] = React.useState(0)
//   const [AdminTotal, setAdminTotal] = React.useState(0)
//   const [AllTotal, setAllTotal] = React.useState(0)
//   const [serachName, setSearchName] = React.useState("")
//   const [serachEmail, setSearchEmail] = React.useState("")
//   const [serachPhone, setSearchPhone] = React.useState("")
//   const GetTotal = (Role, setDataLength, all) => {
//     setReload(true)
//     setProjects1([])
//     const allT = !all ? {
//       "filter[meta_query][1][key]": "user_role",
//       "filter[meta_query][1][value][0]": `${Role}`,
//       "filter[meta_query][1][compare]": "=",
//       per_page: 1
//     } : { per_page: 1 }
//     AdsGETApiAuth(allT, slugs.db_slug_user_data)
//       .then((res) => {
//         setDataLength(res.headers["x-wp-total"])
//         console.log("done")
//       })
//       .catch(err => {
//         console.log(err)
//       }).finally(() => {
//         setReload(false)
//       })
//   }
//   const GetAllProjects = (params) => {
//     setReload(true)
//     setProjects1([])
//     AdsGETApiAuth(params, slugs.db_slug_user_data)
//       .then((res) => {
//         setProjects1(res.data)
//         setCustomersList(res.data)
//         console.log("done")
//       })
//       .catch(err => {
//         console.log(err)
//       }).finally(() => {
//         setReload(false)
//       })
//   }
//   useEffect(() => {
//     GetAllProjects({ per_page: 4999 })
//     GetTotal("subscriber", setSubscriberTotal, false)
//     GetTotal("student", setStudentTotal, false)
//     GetTotal("author", setAdminTotal, false)
//     GetTotal("all", setAllTotal, true)

//   }, [])

//   return (
//     <div>
//       <div className="row" style={{ padding: "30px 30px" }}>
//         <div className="col-md-8 button">
//           <h2>All Students</h2>
//         </div>
//         <div className="col-md-4" style={{ textAlign: 'right' }}>
//           <a href="/">Dashboard</a> / All Students
//         </div>
//       </div>
//       <a href={slugs.new_student}>
//         <div className="sign-in-button-4" style={{ width: "150px", margin: "10px" }}
//           onClick={() => {

//           }}
//         >Add New Student</div>
//       </a>
//       <div className="row">
//         <div className="col-md-6">
//           <div style={{ display: "inline", cursor: "pointer" }}>
//             <div style={{ display: "inline-block", margin: "0px 5px" }}
//               onClick={() => {
//                 setProjects1(CustomersList)
//               }}
//             >
//               All ({AllTotal}) |
//             </div>
//             <div style={{ display: "inline-block", margin: "0px 5px" }}
//               onClick={() => {
//                 setProjects1(CustomersList.filter(a => a.meta.user_role == "subscriber"))
//               }}
//             >
//               Subscribers ({SubscriberTotal}) |
//             </div>
//             <div style={{ display: "inline-block", margin: "0px 5px" }}
//               onClick={() => {
//                 setProjects1(CustomersList.filter(a => a.meta.user_role == "student"))
//               }}
//             >
//               Students ({StudentTotal}) |
//             </div>
//             <div style={{ display: "inline-block", margin: "0px 5px" }}
//               onClick={() => {
//                 setProjects1(CustomersList.filter(a => a.meta.user_role == "author"))
//               }}
//             >
//               Admin ({AdminTotal})
//             </div>
//           </div>
//         </div>
//         <div className="col-md-6 row">
//           <div className="col-4" />
//           <input className="col-2" style={{ margin: "1px 2px", padding: "3px 2px" }}
//             value={serachName} placeholder="Name"
//             onChange={(event) => {
//               setSearchName(event.target.value)
//               setProjects1(CustomersList.filter(a => a.meta.user_name.includes(event.target.value)))
//             }}
//           />
//           <input className="col-3" style={{ margin: "1px 2px", padding: "3px 2px" }}
//             value={serachEmail} placeholder="Email"
//             onChange={(event) => {
//               setSearchEmail(event.target.value)
//               setProjects1(CustomersList.filter(a => a.meta.user_email.includes(event.target.value)))
//             }}
//           />
//           <input className="col-2" style={{ margin: "1px 2px", padding: "3px 2px" }}
//             value={serachPhone} placeholder="Phone"
//             onChange={(event) => {
//               setSearchPhone(event.target.value)
//               setProjects1(CustomersList.filter(a => a.meta.user_phone_number.includes(event.target.value)))
//             }}
//           />
//         </div>
//       </div>

//       <div style={{ backgroundColor: "#fff", padding: "10px", width: "97%", margin: "10px" }}>
//         <DataTable
//           paginationPerPage={100}
//           paginationRowsPerPageOptions={[10, 50, 100, 1000]}
//           columns={columns}
//           data={Projects1}
//           defaultSortFieldId={1}
//           defaultSortAsc={false}
//           striped
//           // sortIcon={<SortIcon />}
//           pagination
//           selectableRows
//         />
//       </div>
//       {Reload &&
//         <div className="modal" style={{ padding: "50vw" }}>
//           <div className="dot-pulse"></div>
//         </div>
//       }
//     </div>
//   )
// }

import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import DataTable from "react-data-table-component";
import { AdsDeleteApi, AdsGETApiAuth,AdsPUTApi } from "../../../Constant/api";
import { slugs } from "../../../Constant/slugs";
const columns = [
  {
    id: 1,
    name: "id",
    selector: (row) => row.meta.user_id,
    sortable: true,
    reorder: true,
  },
  {
    id: 31,
    name: "Title",
    selector: (row) => row.title.rendered,
    sortable: true,
    reorder: true
  },
  {
    id: 312,
    name: "Phone",
    selector: (row) => row.meta.user_phone_number,
    sortable: true,
    reorder: true
  },
  {
    id: 313,
    name: "Email",
    selector: (row) => row.meta.user_email,
    sortable: true,
    reorder: true
  },
  {
    id: 32,
    name: "role",
    selector: (row) => row.meta.user_role,
    sortable: true,
    reorder: true
  },
  {
    id: 3,
    name: "View",
    right: true,
    cell: (row) => <Link to={`${slugs.student_details}${row.slug}`}>View</Link>,

  }
];


const columns2 = [
  {
    id: 1,
    name: "id",
    selector: (row) => row.meta.user_id,
    sortable: true,
    reorder: true,
  },
  {
    id: 31,
    name: "Title",
    selector: (row) => row.title.rendered,
    sortable: true,
    reorder: true
  },
  {
    id: 312,
    name: "Phone",
    selector: (row) => row.meta.user_phone_number,
    sortable: true,
    reorder: true
  },
  {
    id: 313,
    name: "Email",
    selector: (row) => row.meta.user_email,
    sortable: true,
    reorder: true
  },
  {
    id: 32,
    name: "role",
    selector: (row) => row.meta.user_role,
    sortable: true,
    reorder: true
  },
];


export default function AllStudents() {
  const [Projects1, setProjects1] = React.useState([])
  const [CustomersList, setCustomersList] = React.useState([])
  const [Reload, setReload] = React.useState(false)
  const [SubscriberTotal, setSubscriberTotal] = React.useState(0)
  const [StudentTotal, setStudentTotal] = React.useState(0)
  const [AdminTotal, setAdminTotal] = React.useState(0)
  const [AllTotal, setAllTotal] = React.useState(0)
  const [serachName, setSearchName] = React.useState("")
  const [serachEmail, setSearchEmail] = React.useState("")
  const [serachPhone, setSearchPhone] = React.useState("")
  const [toStudentModel, setToStudentModal] = React.useState(false)
  const [Projects2, setProjects2] = React.useState([])
  const [deleteUsersModal, setDeleteUsersModal] = React.useState(false)

  const GetTotal = (Role, setDataLength, all) => {
    setReload(true)
    setProjects1([])
    const allT = !all ? {
      "filter[meta_query][1][key]": "user_role",
      "filter[meta_query][1][value][0]": `${Role}`,
      "filter[meta_query][1][compare]": "=",
      per_page: 1
    } : { per_page: 1 }
    AdsGETApiAuth(allT, slugs.db_slug_user_data)
      .then((res) => {
        setDataLength(res.headers["x-wp-total"])
        console.log("done")
      })
      .catch(err => {
        console.log(err)
      }).finally(() => {
        setReload(false)
      })
  }
  const GetAllProjects = (params) => {
    setReload(true)
    setProjects1([])
    AdsGETApiAuth(params, slugs.db_slug_user_data)
      .then((res) => {
        setProjects1(res.data)
        setCustomersList(res.data)
        console.log("done")
      })
      .catch(err => {
        console.log(err)
      }).finally(() => {
        setReload(false)
      })
  }
  useEffect(() => {
    // GetAllProjects({ per_page: 4999 })
    // GetTotal("subscriber", setSubscriberTotal, false)
    // GetTotal("student", setStudentTotal, false)
    // GetTotal("author", setAdminTotal, false)
    // GetTotal("all", setAllTotal, true)
    GetAll()
  }, [])

  
  const GetAll = () => {
    GetAllProjects({
      per_page: 4999,
      _fields: "id,title,slug,meta"
    })
    GetTotal("subscriber", setSubscriberTotal, false)
    GetTotal("student", setStudentTotal, false)
    GetTotal("author", setAdminTotal, false)
    GetTotal("all", setAllTotal, true)

  }

  return (
    <div>
      <div className="row" style={{ padding: "30px 30px" }}>
        <div className="col-md-8 button">
          <h2>All Students</h2>
        </div>
        <div className="col-md-4" style={{ textAlign: 'right' }}>
          <a href="/">Dashboard</a> / All Students
        </div>
      </div>
      <a href={slugs.new_student}>
        <div className="sign-in-button-4" style={{ width: "150px", margin: "10px" }}
          onClick={() => {

          }}
        >Add New Student</div>
      </a>
      <div className="row">
        <div className="col-md-6">
          <div style={{ display: "inline", cursor: "pointer" }}>
            <div style={{ display: "inline-block", margin: "0px 5px" }}
              onClick={() => {
                setProjects1(CustomersList)
              }}
            >
              All ({AllTotal}) |
            </div>
            <div style={{ display: "inline-block", margin: "0px 5px" }}
              onClick={() => {
                setProjects1(CustomersList.filter(a => a.meta.user_role == "subscriber"))
              }}
            >
              Subscribers ({SubscriberTotal}) |
            </div>
            <div style={{ display: "inline-block", margin: "0px 5px" }}
              onClick={() => {
                setProjects1(CustomersList.filter(a => a.meta.user_role == "student"))
              }}
            >
              Students ({StudentTotal}) |
            </div>
            <div style={{ display: "inline-block", margin: "0px 5px" }}
              onClick={() => {
                setProjects1(CustomersList.filter(a => a.meta.user_role == "ubt_student"))
              }}
            >
              UBT ({CustomersList.filter(a => a.meta.user_role == "ubt_student").length}) |
            </div>
            <div style={{ display: "inline-block", margin: "0px 5px" }}
              onClick={() => {
                setProjects1(CustomersList.filter(a => a.meta.user_role == "chapter_student"))
              }}
            >
              Chapter ({CustomersList.filter(a => a.meta.user_role == "chapter_student").length}) |
            </div>
            <div style={{ display: "inline-block", margin: "0px 5px" }}
              onClick={() => {
                setProjects1(CustomersList.filter(a => a.meta.user_role == "author"))
              }}
            >
              Admin ({AdminTotal}) |
            </div>
            <div style={{ display: "inline-block", margin: "0px 5px", cursor: "pointer" }}
              onClick={() => {
                setToStudentModal(true)
              }}
            >
              Change Role |
            </div>
            <div style={{ display: "inline-block", margin: "0px 5px" }}
              onClick={() => {
                setDeleteUsersModal(true)
              }}
            >
              Delete Users
            </div>
          </div>
        </div>
        <div className="col-md-6 row">
          <div className="col-4" />
          <input className="col-2" style={{ margin: "1px 2px", padding: "3px 2px" }}
            value={serachName} placeholder="Name"
            onChange={(event) => {
              setSearchName(event.target.value)
              setProjects1(CustomersList.filter(a => a.meta.user_name.includes(event.target.value)))
            }}
          />
          <input className="col-3" style={{ margin: "1px 2px", padding: "3px 2px" }}
            value={serachEmail} placeholder="Email"
            onChange={(event) => {
              setSearchEmail(event.target.value)
              setProjects1(CustomersList.filter(a => a.meta.user_email.includes(event.target.value)))
            }}
          />
          <input className="col-2" style={{ margin: "1px 2px", padding: "3px 2px" }}
            value={serachPhone} placeholder="Phone"
            onChange={(event) => {
              setSearchPhone(event.target.value)
              setProjects1(CustomersList.filter(a => a.meta.user_phone_number.includes(event.target.value)))
            }}
          />
        </div>
      </div>

      <div style={{ backgroundColor: "#fff", padding: "10px", width: "97%", margin: "10px" }}>
        <DataTable
          paginationPerPage={100}
          paginationRowsPerPageOptions={[10, 50, 100, 1000]}
          columns={columns}
          data={Projects1}
          defaultSortFieldId={1}
          defaultSortAsc={false}
          striped
          // sortIcon={<SortIcon />}
          pagination
          selectableRows
          onSelectedRowsChange={(r) => {
            console.log("asdasd", r)
            setProjects2(r.selectedRows)
          }}
        />
      </div>
      {toStudentModel &&
        <section className="modal" >
          <div className="row" style={{ overflow: "hidden" }}>
            <div className="col-md-3 col-1"></div>
            <div className="col-md-6 col-10  shadow bg-white">
              <div style={{ margin: " 10px", overflowX: 'hidden', border: "1px solid #999", padding: "9px" }}>
                <div style={{ cursor: 'pointer', textAlign: 'right', padding: "0px 20px", fontWeight: "800" }}
                  onClick={() => {
                    setToStudentModal(false)
                  }}
                >X</div>
                Do you really want to Change all the user roles?<br />
                <div className="row">
                  <div className="col-3 center " >
                    <div className="sign-in-button-4" style={{ width: "80%" }}
                      onClick={() => {
                        setToStudentModal(false)
                      }}
                    >
                      Cancel
                    </div>
                  </div>
                  <div className="col-3 center">
                    <div className="sign-in-button-4" style={{ width: "80%" }}
                      onClick={() => {
                        setToStudentModal(false)
                        for (let i = 0; i < Projects2.length; i++) {
                          setReload(true)
                          AdsPUTApi({
                            "roles": "subscriber"
                          }, "users", Projects2[i].meta.user_id)
                            .then((res) => {
                              AdsPUTApi({
                                meta:{
                                "user_role": "subscriber"
                                }
                              }, slugs.db_slug_user_data, Projects2[i].id)
                                .then((res) => {
                                  if (i == Projects2.length - 1) {
                                    GetAll()
                                  }
                                })
                            })
                            .catch((err) => {
                              console.log(err)
                            })

                        }
                      }}
                    >
                      Change all to Subscriber
                    </div>
                  </div>
                  <div className="col-3 center">
                    <div className="sign-in-button-4" style={{ width: "80%" }}
                      onClick={() => {
                        setToStudentModal(false)
                        for (let i = 0; i < Projects2.length; i++) {
                          setReload(true)
                          AdsPUTApi({
                            "roles": "student"
                          }, "users", Projects2[i].meta.user_id)
                            .then((res) => {
                              AdsPUTApi({
                                meta:{
                                "user_role": "student"
                                }
                              }, slugs.db_slug_user_data, Projects2[i].id)
                                .then((res) => {
                                  if (i == Projects2.length - 1) {
                                    GetAll()
                                  }
                                })
                            })
                            .catch((err) => {
                              console.log(err)
                            })

                        }
                      }}
                    >
                      Change all to Student
                    </div>
                  </div>
                  <div className="col-6 center">
                    <div className="sign-in-button-4" style={{ width: "80%" }}
                      onClick={() => {
                        setToStudentModal(false)
                        for (let i = 0; i < Projects2.length; i++) {
                          setReload(true)
                          AdsPUTApi({
                            "roles": "chapter_student"
                          }, "users", Projects2[i].meta.user_id)
                            .then((res) => {
                              AdsPUTApi({
                                meta: {
                                  "user_role": "chapter_student"
                                }
                              }, slugs.db_slug_user_data, Projects2[i].id)
                                .then((res) => {
                                  if (i == Projects2.length - 1) {
                                    GetAll()
                                  }
                                })
                            })
                            .catch((err) => {
                              console.log(err)
                            })

                        }
                      }}
                    >
                      Verify Chapter Exams
                    </div>
                  </div>

                  <div className="col-6 center">
                    <div className="sign-in-button-4" style={{ width: "80%" }}
                      onClick={() => {
                        setToStudentModal(false)
                        for (let i = 0; i < Projects2.length; i++) {
                          setReload(true)
                          AdsPUTApi({
                            "roles": "ubt_student"
                          }, "users", Projects2[i].meta.user_id)
                            .then((res) => {
                              AdsPUTApi({
                                meta: {
                                  "user_role": "ubt_student"
                                }
                              }, slugs.db_slug_user_data, Projects2[i].id)
                                .then((res) => {
                                  if (i == Projects2.length - 1) {
                                    GetAll()
                                  }
                                })
                            })
                            .catch((err) => {
                              console.log(err)
                            })

                        }
                      }}
                    >
                      Verify UBT Exams
                    </div>
                  </div>

                </div>
                <div style={{ backgroundColor: "#fff", padding: "10px", width: "97%", margin: "10px", border: "1px solid #999" }}>
                  <DataTable
                    paginationPerPage={100}
                    paginationRowsPerPageOptions={[10, 50, 100, 1000]}
                    columns={columns2}
                    data={Projects2}
                    defaultSortFieldId={1}
                    defaultSortAsc={false}
                    striped
                    // sortIcon={<SortIcon />}
                    pagination
                    selectableRows={false}
                  />
                </div>
              </div>
            </div>
          </div>
        </section>

      }
          {deleteUsersModal &&
        <section className="modal" >
          <div className="row" style={{ overflow: "hidden" }}>
            <div className="col-md-3 col-1"></div>
            <div className="col-md-6 col-10  shadow bg-white">
              <div style={{ margin: " 10px", overflowX: 'hidden', border: "1px solid #999", padding: "9px" }}>
                <div style={{ cursor: 'pointer', textAlign: 'right', padding: "0px 20px", fontWeight: "800" }}
                  onClick={() => {
                    setDeleteUsersModal(false)
                  }}
                >X</div>
                Do you really want to Delete all the user?<br />
                <div className="row">
                  <div className="col-3 center " >
                    <div className="sign-in-button-4" style={{ width: "80%" }}
                      onClick={() => {
                        setDeleteUsersModal(false)
                      }}
                    >
                      Cancel
                    </div>
                  </div>
                  <div className="col-3 center">
                    <div className="sign-in-button-4" style={{ width: "80%" }}
                      onClick={() => {
                        for (let i = 0; i < Projects2.length; i++) {
                          setReload(true)
                          setDeleteUsersModal(false)
                          AdsDeleteApi("users", Projects2[i].meta.user_id)
                            .then((res) => {
                              AdsPUTApi({
                                status: "draft"
                              }, slugs.db_slug_user_data, Projects2[i].id)
                                .then((res) => {
                                  if (i == Projects2.length - 1) {
                                    GetAll()
                                  }
                                })
                            })
                            .catch((err) => {
                              console.log(err)
                            })

                        }
                      }}
                    >
                      Delete all users
                    </div>
                  </div>
                </div>
                <div style={{ backgroundColor: "#fff", padding: "10px", width: "97%", margin: "10px", border: "1px solid #999" }}>
                  <DataTable
                    paginationPerPage={100}
                    paginationRowsPerPageOptions={[10, 50, 100, 1000]}
                    columns={columns2}
                    data={Projects2}
                    defaultSortFieldId={1}
                    defaultSortAsc={false}
                    striped
                    // sortIcon={<SortIcon />}
                    pagination
                    selectableRows={false}
                  />
                </div>
              </div>
            </div>
          </div>
        </section>

      }
      {Reload &&
        <div className="modal" style={{ padding: "50vw" }}>
          <div className="dot-pulse"></div>
        </div>
      }
    </div>
  )
}